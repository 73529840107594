const isClient = typeof window !== 'undefined';
const isServer = !isClient;

global.ENV = Object.fromEntries(
  Object.entries(
    global.ENV ||
      (typeof process !== 'undefined' &&
        process.env !== undefined &&
        process.env) ||
      {}
  )
    .filter(([key]) => key.startsWith('PUBLIC_') || key.startsWith('PRIVATE_'))
    .map(([key, value]) => [key, value?.replace(/^"(.*)"$/, '$1')])
) as unknown as typeof global.ENV;

export const getPublicEnvVars = (): PublicEnvVars => {
  if (isServer && global.ENV === undefined) {
    throw new TypeError('global.ENV is undefined.');
  }

  if (isClient) {
    return window.ENV;
  }

  return Object.fromEntries(
    Object.entries(global.ENV).filter(([key]) => key.startsWith('PUBLIC_'))
  ) as PublicEnvVars;
};

export const getPrivateEnvVars = (): PrivateEnvVars => {
  if (global.ENV === undefined) {
    throw new TypeError('global.ENV is undefined.');
  }

  if (isClient) {
    throw new Error('Cannot get private env vars on the client');
  }

  return Object.fromEntries(
    Object.entries(global.ENV).filter(([key]) => key.startsWith('PRIVATE_'))
  ) as PrivateEnvVars;
};
